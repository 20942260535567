import React, { useState, useEffect } from 'react';
import getWeb3 from '../utils/getWeb3';
import GameListingContract from '../contracts/GameListing.json';

function GameGrid() {
  const [games, setGames] = useState([]);

  useEffect(() => {
    const loadGames = async () => {
      try {
        const web3 = await getWeb3();
        const networkId = await web3.eth.net.getId();
        const gameListingNetwork = GameListingContract.networks[networkId];
        const gameListing = new web3.eth.Contract(
          GameListingContract.abi,
          gameListingNetwork && gameListingNetwork.address,
        );

        const gameCount = await gameListing.methods.gameCount().call();
        const gamesList = [];
        for (let i = 0; i < gameCount; i++) {
          const game = await gameListing.methods.getGame(i).call();
          gamesList.push({ id: i, name: game[0], genre: game[1], developer: game[2], isActive: game[3] });
        }
        setGames(gamesList);
      } catch (error) {
        console.error("Error loading games:", error);
      }
    };

    loadGames();
  }, []);

  return (
    <div className="game-grid">
      {games.map(game => (
        <div key={game.id} className="game-card">
          <img src={`https://via.placeholder.com/150?text=${game.name}`} alt={game.name} />
          <h3>{game.name}</h3>
          <p>{game.genre}</p>
        </div>
      ))}
    </div>
  );
}

export default GameGrid;
