import React, { useState } from 'react';
import getWeb3 from '../utils/getWeb3';

function Header({ appName }) {
  const [account, setAccount] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isGamesDropdownOpen, setIsGamesDropdownOpen] = useState(false);
  const [isMeeTokenDropdownOpen, setIsMeeTokenDropdownOpen] = useState(false);

  const connectWallet = async () => {
    try {
      const web3 = await getWeb3();
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const accounts = await web3.eth.getAccounts();
      if (accounts.length > 0) {
        setAccount(accounts[0]);
      }
    } catch (error) {
      console.error("Error connecting wallet:", error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleGamesDropdown = () => {
    setIsGamesDropdownOpen(!isGamesDropdownOpen);
  };

  const toggleMeeTokenDropdown = () => {
    setIsMeeTokenDropdownOpen(!isMeeTokenDropdownOpen);
  };

  return (
    <header className="app-header">
      <div className="header-content">
        <div className="logo">
          <img src="/logo.png" alt={`${appName} Logo`} />
          <h1>{appName}</h1>
        </div>
        <nav className={`main-nav ${isMenuOpen ? 'open' : ''}`}>
          <ul>
            <li><a href="/">Home</a></li>
            <li className="dropdown">
              <a href="/games" onClick={toggleGamesDropdown}>Games</a>
              {isGamesDropdownOpen && (
                <ul className="dropdown-menu">
                  <li>
                    <a href="/games">
                      <strong>Games</strong>
                      <p>Discover the latest blockchain games. Find the perfect game for you!</p>
                    </a>
                  </li>
                  <li>
                    <a href="/reviews">
                      <strong>Reviews</strong>
                      <p>Unbiased and honest blockchain game reviews on all the trending and newly released games.</p>
                    </a>
                  </li>
                  <li>
                    <a href="/guides">
                      <strong>Guides</strong>
                      <p>Unlock the full potential of web3 gaming with our comprehensive guides, tips, and strategies.</p>
                    </a>
                  </li>
                  <li>
                    <a href="/genres">
                      <strong>Genres</strong>
                      <p>Dive into the various blockchain gaming genres and find the perfect game for you.</p>
                    </a>
                  </li>
                  <li>
                    <a href="/analytics">
                      <strong>Analytics</strong>
                      <p>Get insights into the blockchain gaming industry. Discover the latest trends and statistics.</p>
                    </a>
                  </li>
                </ul>
              )}
            </li>
            <li><a href="/quests">Quests</a></li>
            <li><a href="/news">News</a></li>
            <li><a href="/vault">Vault</a></li>
            <li><a href="/gamers-nft">GAM3RS NFT</a></li>
            <li className="dropdown">
              <a href="/mee-token" onClick={toggleMeeTokenDropdown}>$MEE Token</a>
              {isMeeTokenDropdownOpen && (
                <ul className="dropdown-menu">
                  <li>
                    <a href="/mee-token">
                      <strong>$MEE Token</strong>
                      <p>Get your hands on the $MEE token. Buy, sell, and trade on the best exchanges.</p>
                    </a>
                  </li>
                  <li>
                    <a href="/staking">
                      <strong>Staking</strong>
                      <p>Stake your $MEE tokens and earn rewards. The more you stake, the more you earn!</p>
                    </a>
                  </li>
                </ul>
              )}
            </li>
            <li><a href="/awards">Awards</a></li>
          </ul>
        </nav>
        <div className="header-actions">
          <button onClick={connectWallet}>Connect Wallet</button>
          <button className="login-btn">Login</button>
          <button className="signup-btn">Sign Up</button>
          <button className="menu-toggle" onClick={toggleMenu}>
            <span className="menu-icon"></span>
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
