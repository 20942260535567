import React, { useState, useEffect } from 'react';
import getWeb3 from '../utils/getWeb3';
import GameListingContract from '../contracts/GameListing.json';

function FeaturedGames() {
  const [featuredGames, setFeaturedGames] = useState([]);

  useEffect(() => {
    const loadFeaturedGames = async () => {
      try {
        const web3 = await getWeb3();
        const networkId = await web3.eth.net.getId();
        const gameListingNetwork = GameListingContract.networks[networkId];
        const gameListing = new web3.eth.Contract(
          GameListingContract.abi,
          gameListingNetwork && gameListingNetwork.address,
        );

        const gameCount = await gameListing.methods.gameCount().call();
        const gamesList = [];
        for (let i = 0; i < gameCount && i < 5; i++) {  // Limit to 5 featured games
          const game = await gameListing.methods.getGame(i).call();
          gamesList.push({ id: i, name: game[0], genre: game[1], developer: game[2], isActive: game[3] });
        }
        setFeaturedGames(gamesList);
      } catch (error) {
        console.error("Error loading featured games:", error);
      }
    };

    loadFeaturedGames();
  }, []);

  return (
    <div className="featured-games">
      <h2>Featured Games</h2>
      <div className="featured-games-list">
        {featuredGames.map(game => (
          <div key={game.id} className="featured-game-card">
            <img src={`https://via.placeholder.com/300x200?text=${game.name}`} alt={game.name} />
            <h3>{game.name}</h3>
            <p>{game.genre}</p>
            <button>Play Now</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FeaturedGames;
