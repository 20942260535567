import React from 'react';

function Footer({appName}) {
  return (
    <footer className="app-footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>About {appName}</h3>
          <p>{appName} is a decentralized platform for blockchain games. Discover, play, and earn with the latest in blockchain gaming technology.</p>
        </div>
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#games">Games</a></li>
            <li><a href="#leaderboard">Leaderboard</a></li>
            <li><a href="#about">About</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Connect With Us</h3>
          <ul className="social-links">
            <li><a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a></li>
            <li><a href="https://discord.com" target="_blank" rel="noopener noreferrer">Discord</a></li>
            <li><a href="https://telegram.org" target="_blank" rel="noopener noreferrer">Telegram</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} {appName}. All rights reserved.</p>
      </div>
      // In Footer.js, if you decide to add a logo here  
      <div className="footer-logo">
        <img src="/logo.png" alt="MeeGames Logo" />
      </div>
    </footer>
  );
}

export default Footer;
