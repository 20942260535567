// src/components/HeroSlider.js
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeroSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const games = [
    {
      id: 1,
      title: "Crypto Legends",
      description: "Battle in the blockchain arena!",
      imageUrl: "/images/crypto-legends.jpg",
    },
    {
      id: 2,
      title: "NFT Racers",
      description: "Race your unique NFT cars to victory!",
      imageUrl: "/images/nft-racers.jpg",
    },
    {
      id: 3,
      title: "Decentraland Adventures",
      description: "Explore a vast virtual world powered by blockchain.",
      imageUrl: "/images/decentraland-adventures.jpg",
    },
    {
      id: 4,
      title: "Ethereum Empires",
      description: "Build your blockchain empire and conquer the crypto world!",
      imageUrl: "/images/ethereum-empires.jpg",
    },
    {
      id: 5,
      title: "CryptoKitties Unleashed",
      description: "Breed and collect unique digital cats on the blockchain!",
      imageUrl: "/images/cryptokitties-unleashed.jpg",
    },
  ];

  return (
    <div className="hero-slider">
      <Slider {...settings}>
        {games.map((game) => (
          <div key={game.id} className="hero-slide">
            <img src={game.imageUrl} alt={game.title} />
            <div className="hero-content">
              <h2>{game.title}</h2>
              <p>{game.description}</p>
              <button className="cta-button">Play Now</button>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HeroSlider;
