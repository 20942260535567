import React from 'react';
import './App.css';
import Header from './components/Header';
import FeaturedGames from './components/FeaturedGames';
import HeroSlider from './components/HeroSlider';
import GameGrid from './components/GameGrid';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header appName="MeeGames" />
	<main>
        <HeroSlider />
        {/* Other content */}
      	</main>
      <FeaturedGames />
      <GameGrid />
      <Footer appName="MeeGames" />
    </div>
  );
}

export default App;
